import { useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import merge from 'deepmerge';

let apolloClient;

// Strapi GraphQL Implementation
const httpLinkStrapi = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/graphql`,
});

// Vert GraphQL Implementation
const httpLinkVert = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL}/graphql`,
  credentials: 'same-origin',
});

let activeSocket;
let timedOut;

// Vert GraphQL Subscriptions Implementation
const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_BASE_URL}/graphql`,
    // Reconnection logic
    shouldRetry: () => true, // Always return true to keep retrying indefinitely
    retryAttempts: Infinity, // Specify the number of retry attempts
    on: {
      // Event listeners for handling connection events
      connected: (socket) => {
        activeSocket = socket; // to be used at pings & pongs
        console.log('Connected to the WebSocket server');
      },
      ping: (received) => {
        if (!received)
          // sent
          timedOut = setTimeout(() => {
            if (activeSocket?.readyState === WebSocket?.OPEN)
              activeSocket?.close(4408, 'Request Timeout');
          }, 5000); // wait 5 seconds for the pong and then close the connection
      },
      pong: (received) => {
        if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
      },
    },
  })
);

const authLink = new ApolloLink((operation, forward) => {
  const token = process.env.NEXT_PUBLIC_STRAPI_API_KEY;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

function createIsomorphLink() {
  return ApolloLink.from([
    authLink,
    new ApolloLink((operation, forward) => {
      // Get the authorization token from your authentication system
      const token = localStorage.getItem('vert_auth_token');

      // Set the authorization token in the request headers
      operation.setContext(({ headers = {} }) => ({
        clientName: 'vert',
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }));

      return forward(operation);
    }),
    httpLinkVert,
  ]);
}

function createApolloClient() {
  const splitLink = split(
    (operation) => {
      return operation.getContext().clientName === 'strapi';
    },
    authLink.concat(httpLinkStrapi),
    authLink.concat(createIsomorphLink())
  );

  const link = ApolloLink.split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    splitLink
  );

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: link,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
